.background {
  padding-top: 80px;
}

.store-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
}

.dog {
  margin-left: 1px;
}
.dog-heading {
  font-size: 50px;
  font-family: Lato;
  font-style: italic;

  color: #2146c7;
  padding-bottom: 30px;
  text-align: center;
}
.dog-para {
  font-size: 26px;
}
.download-1 {
  width: 300px;
  height: 55px;
  /* top: 367px;
  left: 262px; */
  padding: 21px, 57px, 20px, 57px;
  border-radius: 15px;
  border: 1px solid black;
  color: black;
  font-size: 25px;
  background-color: transparent;
  font-weight: bold;
  margin-right: 130px;
}
.android-image {
  width: 390px;
  height: 170px;
  cursor: pointer;
}
.download-2 {
  width: 300px;
  font-size: 25px;
  height: 55px;
  /* top: 367px;
  left: 262px; */
  padding: 21px, 57px, 20px, 57px;
  border-radius: 15px;
  border: 1px solid black;
  color: black;
  margin-left: 150px;
  font-weight: bold;
  background-color: transparent;
}
.download-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  /* margin-top: 90px; */
  gap: 100px;
}
.btn-cont {
  /* background-color: #2146c7; */
  margin-top: 50px;
}
@media (max-width: 575.98px) {
  .background {
    padding: 10px;
    /* background-color: aqua; */
    border-bottom: 3px solid rgb(234, 234, 234);
  }
  .dog-heading {
    font-size: 24px;
    font-family: Lato;
    font-style: italic;

    color: #2146c7;
    padding-bottom: 10px;
    text-align: center;
  }
  .dog-para {
    font-size: 16px;
    font-family: "Lato";
  }
  .btn-cont {
    /* background-color: #2146c7; */
    margin-top: 30px;
    /* padding: 1px; */
  }
  .download-container {
    display: flex;

    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    text-align: center;
    gap: 0px;
    background-color: aqua;
    /* margin-top: 0px; */
  }
  .android-image {
    width: 160px;
    height: 130px;
    cursor: pointer;
  }

  .download-1 {
    /* width: 200px;
    height: 55px;
 
    padding: 21px, 57px, 20px, 57px;
    border-radius: 15px;
    border: 1px solid black;
    color: black;
    font-size: 25px;
    background-color: transparent;
    font-weight: bold;*/
    margin-right: 0px;
    width: 180px;
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 16px;
    height: 45px;
  }
  .download-2 {
    /* width: 200px;
    font-size: 25px;
    height: 55px;
    
    padding: 21px, 57px, 20px, 57px;
    border-radius: 15px;
    border: 1px solid black;
    color: black;
    margin-left: 150px;
    font-weight: bold;
    background-color: transparent; */
    margin-left: 0px;
    width: 180px;
    max-width: fit-content;
    font-weight: 600;
    font-size: 16px;

    height: 45px;
  }
  .dog {
    margin-left: 0px;
    /* width: 180px; */
  }
}
