.container-input {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .otp-hidden-input {
    width: 1px;
    height: 1px;
    opacity: 0;
    position: absolute;
  }
  
  .cell-view {
    width: 50px;
    height: 60px;
    margin: 0 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    border: 1px solid #ff9d81;
    margin-bottom: 40px;
  }
  
  .small-cell-view {
    width: 48px;
    height: 48px;
  }
  
  .active-cell-view {
    border-width: 1px;
  }
  
  .otp-text {
    font-size: 24px;
    color: #2e3038;
  }
  
  .otp-active-text {
    color: black;
  }
  
  .error-cell-view {
    border-color: red;
    background-color: #fff;
  }
  
  .error-active-text {
    color: red;
  }
  
  .cell-button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
  
  /* Responsive styles for mobile */
  @media (max-width: 768px) {
    .cell-view {
      width: 40px;
      height: 50px;
      margin: 0 4px;
    }
  
    .otp-text {
      font-size: 20px;
    }
  
    .container-input {
      margin-bottom: 15px;
    }
  }
  
  /* Further adjustments for small mobile devices */
  @media (max-width: 480px) {
    .cell-view {
      width: 35px;
      height: 45px;
      margin: 0 3px;
    }
  
    .otp-text {
      font-size: 18px;
    }
  
    .container-input {
      margin-bottom: 10px;
    }
  }
  