.main {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/* .mbl-container { 
  margin-left: 50px; 
} */

.mobile {
  width: 50px;
  height: 50px;
  width: 100%;
  height: 100%;
  /* padding-left: 50px; */
  /* padding-right: 100px; */
  /* background-color: antiquewhite; */
}
.para-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  /* padding-right: 30px; */
  /* margin-right: 30px; */
  padding-top: 160px;
  /* background-color: aquamarine; */
}
.mobile-heading {
  font-size: 25px;
  padding-bottom: 25px;
  font-weight: bold;
  width: 90%;
  max-width: fit-content;
  /* font-family: Lato; */
  /* font-family: "Roboto"; */
}

.mobile-para {
  /* width: 600px; */
  max-width: fit-content;
  font-family: "Lato", sans-serif;
  /* font-family: cursive; */
}

/* business page */
.business {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.business-img {
  /* background-color: antiquewhite; */
  width: 500px;
}
.business-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  /* margin-right: 50px; */
  padding: 50px;
  padding-top: 10px;
}
.business-heading {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
  padding: 30px;
  margin-left: 0px;
  font-family: "Lato", sans-serif;
}
.business-button {
  width: 200px;
  height: 50px;
  border-radius: 13px;
  margin-left: 50px;
  margin-right: 40px;
  background-color: #FF7A54;
  border-color: #FF7A54;
  font-size: 21px;
  font-family: "Lato", sans-serif;
  color: aliceblue;
  border: 0px solid;
  font-weight: bold;
}

.business-button:hover {
  background-color: #FF7A54;
  border-color: #FF7A54;
}
@media (max-width: 575.98px) {
  .main {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    min-width: 390px;
    max-width: fit-content;

    /* padding-left: 10px; */
  }
  .mobile {
    /* width: 390px; */
    width: 100%;
    padding: 35px;
    padding-left: 0px;
  }
  .mob-para-cont {
    border-bottom: 3px solid rgb(234, 234, 234);
  }
  .para-container {
    display: flex;
    flex-direction: column;
    /* flex-wrap: wrap; */
    padding-left: 5px;
    /* padding-right: 5px; */
    padding-top: 20px;
    /* background-color: aqua; */
  }

  .business {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  .business-img {
    width: 390px;
  }
  .business-container {
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 10px;
    border-bottom: 3px solid rgb(234, 234, 234);
  }
  .business-heading {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
  }
  .business-button {
    height: 50px;
    width: 200px;
    font-size: 16px;
    font-weight: bold;
    margin-left: 0px;
    padding: 16px, 46px, 16px, 46px;
    /* background-color: aqua; */
    margin-right: 0px;
  }
}
