/* General Styling */
.aboutus-section {
    background-color: #E66A42;
    text-align: center;
    padding: 20px;
    color: white;
}

.aboutus-heading {
    font-size: 10rem;
}

.aboutus-subheading {
    font-size: 2rem;
    font-weight: bold;
}

.aboutus-image {
    width: 50%;
}

.founders-section {
    padding: 20px;
}

.section-title {
    font-size: 4rem;
    text-decoration: underline;
}

.aboutsection {
    font-size: 3rem;
    color: dimgray;
}

.founders-wrapper {
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.story-wrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.founder {
    width: 30%;
}

.story-image {
    width: 30%;
}

.founder-description, .story-description {
    width: 40%;
}

/* .social-media-section {
    text-align: center;
    padding: 20px;
    background-color: #E66A42;
} */

.social-icon {
    margin: 10px;
    color: white;
    text-decoration: none;
}

.story-section {
    background: linear-gradient(9.14deg, #f2987e 21.71%, #ffc0ab 99.53%);
  }
/* Responsive Styling for Mobile and Tablets */
@media (max-width: 1024px) {
    .aboutus-heading {
        font-size: 5rem;
    }

    .aboutus-image, .founder-image, .story-image {
        width: 100%;
    }

    .founders-wrapper, .story-wrapper {
        flex-direction: column;
    }

    .founder-description, .story-description {
        width: 80%;
        margin: 20px 0;
    }

    .social-icon {
        margin: 20px;
    }

    .founder {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .aboutus-heading {
        font-size: 4rem;
    }

    .aboutus-image, .founder-image, .story-image {
        width: 100%;
    }

    .founder-description, .story-description {
        width: 90%;
        margin: 20px 0;
    }

    .social-icon {
        margin: 15px;
    }

    .founder {
        width: 100%;
    }
}

@media (max-width: 480px) {
    .aboutus-heading {
        font-size: 3rem;
    }

    .aboutus-image, .founder-image, .story-image {
        width: 100%;
    }

    .founder-description, .story-description {
        width: 95%;
        margin: 15px 0;
    }

    .social-icon {
        margin: 10px;
    }

    .founder {
        width: 100%;
    }
}
