.maincontainer {
  background: #fff;
  flex: 1;
  /* padding-left: 40px;
    padding-right: 40px; */
  /* width: 1440px;
    height: 1024px; */
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8f8;
  border-bottom: 3px solid rgb(234, 234, 234);
}

.brand {
  color: #707070;
  /* font-family: Lato; */
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 5px;
}
.homeimage {
  margin-top: 65px;
}
.body-text {
  display: flex;
  flex-direction: row;
  gap: 213px;
  justify-content: space-around;
  align-items: center;
  /* background-color: #c13737; */
}
.body {
  display: flex;

  flex-direction: column;
  align-items: center;

  margin-top: 50px;
}
.body-icons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.calendar-icon {
  color: #FF7A54;
  font-size: 19px;
  border-radius: 8px;
  padding: 9px 13px;
  border: 1px solid #bec8ff;
}
.svg {
  border-radius: 8px;
  padding: 10px;
  border: 1px solid #bec8ff;
}

.text {
  text-align: center;

  font-family: Lato;
  font-size: 12px;
  font-style: normal;
  margin: 10px;
  /* width: 250px; */
}

.body-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 35px;
}

.logo-button {
  background: none;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: transform 0.1s ease;
}

.logo-button:active {
  transform: scale(0.95);
}

.logo-button p {
  margin: 0;
  color: inherit;
  text-decoration: none;
}

.onelovelogo {
  width: 3rem;
  height: auto;
  margin: 0;
  margin-right: 10px;
}

.logo p {
  margin: 0;
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
}



@media (max-width: 575.98px) {
  .calendar-icon {
    color: #FF7A54;
    font-size: 25px;
    border-radius: 8px;
    padding: 3px 10px;
    border: 1px solid #bec8ff;
  }
  .svg {
    border-radius: 8px;
    padding: 9px;
    border: 1px solid #bec8ff;
  }
  .body {
    width: 100%;
    /* border: 2px solid green; */
  }
  .body-text {
    display: flex;
    flex-direction: row;
    gap: 22px;
    /* width: 350px; */
    justify-content: space-around;
    align-items: center;
    /* background-color: #c13737; */
  }

  .text {
    /* color: #000;
    text-align: center;
    font-feature-settings: "clig" off, "liga" off; */
    font-family: Lato;
    font-size: 13px;
    font-style: normal;
    /* font-weight: 500; */
    /* line-height: normal; */
    margin: 14px;

    /* background-color: rgb(184, 55, 113); */
  }
  /* .homeimage {
    border-bottom: 3px solid rgb(234, 234, 234);
  } */
  .nav-iconn {
    border: 0px solid;
  }
}
