.terms-container {
    padding: 5%;
    background-color: rgba(255, 253, 252, 1);
    font-family: Arial, sans-serif;
    line-height: 1.6;
}

.terms-title {
    font-size: 1.8em;
    margin-bottom: 10px;
}

.terms-side {
    font-size: 1.3em;
    font-weight: bold;
}
ul {
    padding-left: 20px;
}

li {
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .terms-container {
        padding: 2%;
        margin-top: 22%;
    }

    .terms-title {
        font-size: 1.5em;
    }

    h2 {
        font-size: 1.3em;
    }

    h3 {
        font-size: 1.2em;
    }

    li {
        font-size: 0.9em;
    }
}

@media (max-width: 480px) {
    .terms-container {
        padding: 1%;
    }

    .terms-title {
        font-size: 1.2em;
    }

    h2 {
        font-size: 1.1em;
    }

    h3 {
        font-size: 1em;
    }

    li {
        font-size: 0.8em;
    }
}
