.social-media-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* padding-left: 5rem; */
    background-color: #606060;
    justify-content: center;
}

.social-icons {
    display: flex;
    gap: 20px;
}

.social-icon {
    color: #333;
}

.delete-account-button {
    padding: 10px 20px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.delete-account-button:hover {
    background-color: #ff3333;
}

/* Modal styling */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000; /* Ensure it's on top */
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    text-align: center;
    width: 300px;
}

.modal-content input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.confirm-delete-button {
    padding: 10px 20px;
    background-color: #ff4d4d;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirm-delete-button:hover {
    background-color: #ff3333;
}

.close-button {
    padding: 10px 20px;
    background-color: #ccc;
    color: black;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
}

.our-socials-text {
    font-size: 18px;
    color: #FFF;
    margin-right: 10px;
}

@media (max-width: 768px) {
    .social-icon svg {
        width: 30px;
        height: 30px;
    }
    .our-socials-text {
        display: none;
    }
}
