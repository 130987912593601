@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

body {
  font-family: Lato;
}

.bg-cont {
  background-size: cover;
  height: 100vh;
}

.btnn {
  margin-top: 20px;
  background-color: rgb(0, 183, 255);
  border-radius: 5px;
  border-width: 0px;
  padding: 5px 15px 5px 15px;
  border-style: none;
}

.btn1 {
  width: 190px;
  margin: 16px 0px 10px 0px;
}

.login-text {
  padding-bottom: 12px;
}

.input-text {
  border-radius: 3px;
  border: 1px solid;
  padding: 4px;
}

.button-side {
  background-color: rgb(71, 71, 211);
  border-radius: 40px;
  border-style: none;
  padding: 5px;
}

/* Home page css */
.first-conatiner {
  padding: 10px 40px 10px 40px;

  margin-bottom: 60px;
  border: 4px solid #2146c7;
}

.image-2 {
  /* width: auto; */
  /* height: auto; */
  padding: 10px;
  /* margin: 50px; */
}

.sub-first-container {
  width: Fixed (1, 440px);
  height: Fixed (500px);
  padding: 10px, 60px, 10px, 100px;
}

.one-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.images {
  width: 150px;
  height: 174.09px;
  border-radius: 76px;
}

.heading-text {
  width: 561px;
  height: 30px;
  top: 156.52px;
  left: 100px;
  font-family: "Roboto";
}

.heading-1 {
  font-size: 24px;
  font-weight: 600;
}

.left-container {
  padding-top: 150px;
  margin-top: 40px;
}

.right-container {
  gap: 30px;
  padding-top: 90px;

  padding-left: 100px;
}

.pet-row {
  gap: 50px;
  margin-left: 160px;
}

.images-3 {
  gap: 250px;
  padding: 15px;
}

.head {
  font-size: 21px;
  font-weight: 700;

  padding-bottom: 10px;
}

.para {
  font-size: 16px;
}

.para-1 {
  font-size: 16px;
}

.image-1 {
  width: auto;
  height: auto;
  padding: 10px;
  gap: 10px;
}

.second-container {
  gap: 10px;
  background-color: aqua;
  margin-top: 130px;
  margin-bottom: 100px;
}

.second-cont {
  padding-top: 100px;
}

.cont-2 {
  padding-top: 30px;
}

.where-love-cont {
  margin-top: 40px;
}

.third-conatiner {
  padding: 10px 60px 10px 100px;
  height: 376.34px;
  margin-bottom: 60px;
}

/* Contact us page css */
.contact-heading {
  font-size: 30px;
  font-family: "Lato", sans-serif;
  margin-bottom: 20px;
}

.contact-para {
  font-size: 14px;
  font-family: "Lato", sans-serif;
}

.top-contact {
  padding: 80px;
  font-family: "Lato";
}

.left-top-contact {
  padding-top: 70px;
}

.right-top-contact {
  padding: 40px;
  gap: 18px;
}

.bottom-contact {
  padding-top: 40px;
}

.bottom-para {
  font-size: 16px;
  font-family: "Lato", sans-serif;
}

.fullname-mail {
  width: 80%;
  height: 0%;
  border-radius: 7px;
  border: 1px solid #979797;
  padding: 10px;
  margin: 8px;
  padding-left: 11px;
  font-family: "Lato", sans-serif;
  margin-left: 50px;
}

.message {
  width: 80%;
  height: 40%;
  top: 334px;
  left: 750px;
  padding: 17px, 438px, 154px, 19px;
  border-radius: 15px;
  border: 1px solid #979797;
  padding: 10px;
  margin: 12px;
  margin-left: 50px;
  font-family: "Lato", sans-serif;
}

/* .send-button {
  width: 180px;
  height: 50px;
  padding: 20px, 76px, 19px, 76px;
  border-radius: 13px;
  margin-top: 30px;
  background: #2146c7;
  font-size: 21px;
  color: aliceblue;
  border: 0px solid;
  font-weight: bold;
} */

.line {
  padding: 2px;
  background-color: #FF7A54;
  width: 250px;
}

.main-line {
  color: rgb(227, 226, 226);
  width: 100%;
}

.send-button {
  width: 180px;
  height: 50px;
  padding: 20px, 76px, 19px, 76px;
  border-radius: 13px;
  margin-top: 30px;
  background-color: #FF7A54;
  border-color: #FF7A54;
  font-size: 21px;
  color: aliceblue;
  border: 0px solid;
  font-weight: bold;
}

.send-button:hover {
  background-color: #E66A42;
  /* Darker shade of orange */
  border-color: #E66A42;
}

.paratext {
  color: #000000;
  font-size: 12pt;
  font-family: "Times New Roman";
}

.subparatext {
  margin-left: 1.5rem;
  color: '#000000';
  font-size: 12pt;
  font-family: "Times New Roman"
}

.headtext {
  color: '#000000';
   font-size: 14pt;
    font-family: "Times New Roman";
}

.parasubtext {
  margin-left: 3rem; color: '#000000'; font-size: 12pt; font-family: "Times New Roman"
}
.parasubtext2 {
  margin-left: 5rem; color: '#000000'; font-size: 12pt; font-family: "Times New Roman"
}
.nav-link {
  font-size: 12pt;
  font-family: 'Times New Roman';
  font-weight:800;
}

.justify-content-end {
  background-color: #FF7A54;
}
.login-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100dvh;
}

.loginmaincontainer {
  background-image: url('../public/assets/orangebg.jpg');
  background-size: cover;
}

.backarrow {
  color: #000000;
  cursor: pointer;
  margin-top: 7rem;
  margin-left: 4rem;
}
.welcomeTitle {
  position: relative;
  font-weight: 700;
  /* padding: 20px 15px; */
}

.CardContainer {
  display: flex;
  height: 100%;
  width: 30%;
  align-items: center;
  justify-content: center;
}

.otp-input-container {
  display: flex;
  justify-content: center;
  gap: 10px;
}
.otp-input-container input {
  margin: 10px;
}

.input {
  width: 40px;
  height: 40px;
  font-size: 16px;
  text-align: center;
}

.input-container {
  margin: 0 auto;
}

.input-wrapper {
  border: 1px solid #ff7a5430;;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  /* align-items: center; */
  align-items: baseline;
  background-color: #ff7a5410;
}

.input-wrapper p {
  margin-bottom: 5px;
}
.emoji-dialcode {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label1 {
  margin-bottom: 1rem;
}

.form-control {
  border: 1px solid black;
}

.svg-custom {
  width: 300px;
  height: auto;
  margin-left: 5rem;
}

.contactuscontainer {
  margin-top: 5rem;
}

.row-top-contact {
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  padding: 2rem;
}

.top-contact {
  flex-grow: 1;
}

.users-container {
height: 600px;
width: 100%;
padding: 1rem;
margin-top: 5%;
}

.bottom-contact {
  margin-bottom: 3rem;
}

@media (max-width: 575.98px) {
  
  .users-container {
    margin-top: 20%;
    }
  .main-line {
    color: rgb(227, 226, 226);
    width: 100%;
  }

  .bg-cont {
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  .line {
    padding: 2px;
    background-color: #FF7A54;

    width: 75px;
  }

  .contact-heading {
    font-size: 24px;
    font-family: "Lato", sans-serif;
    margin-bottom: 20px;
    font-weight: 700;
  }

  .first-conatiner {
    padding: 1px 6px 1px 10px;
  }

  .second-container {
    gap: 0px;
    background-color: aqua;
    margin-top: 13px;
    margin-bottom: 1px;
    padding-left: 1px;
  }

  .second-cont {
    padding-top: 20px;
    border-bottom: 3px solid rgb(234, 234, 234);
  }

  .left-container {
    padding-top: 2px;
  }

  .right-container {
    gap: 3px;
    padding-top: 1px;
    padding-left: 0.1px;
  }

  .pet-row {
    gap: 56px;
    margin-left: 23px;
  }

  .image-1 {
    width: (390px);
    height: (550px);
    padding: 2px;
    padding-left: 1px;
  }

  .head {
    font-size: 19px;
    font-weight: 700;
  }

  .image-2 {
    padding: 8px;
  }

  .images-3 {
    gap: 0px;
    padding: 1px;
  }

  .top-contact {
    padding: 1px;
  }

  .fullname-mail {
    width: 100%;
    height: 3rem;
    border-radius: 7px;
    border: 1px solid #979797;
    padding-left: 10px;
    margin: 4px;
  }

  .heading-1 {
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 2px;
  }

  .message {
    width: 100%;
    height: 42%;
    border-radius: 15px;
    border: 1px solid #979797;
    margin: 4px;
    margin-left: 4px;
  }

  .para {
    font-size: 14px;
  }

  .head {
    width: 390px;
  }

  .send-button {
    background-color: #FF7A54;
    border-color: #FF7A54;
  }

  .login-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  .loginmaincontainer {
    background-image: url('../public/assets/orangebg.jpg');
    background-size: cover;
  }
  .CardContainer {
    display: flex;
    height: 100%;
    width: 70%;
    align-items: center;
    justify-content: center;
  }
  .welcomeTitle {
    top: 5rem;
  }
  .backarrow {
    color: #000000;
    cursor: pointer;
    margin-top: 6rem;
    margin-left: 2rem;
  }

  .svg-custom {
    width: 300px;
    height: auto;
    margin-left: 0rem;
  }

  .row-top-contact {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    padding: 2rem;
  }
  .contactuscontainer {
    margin-top: 0rem;
  }

  .aboutus-section {
    margin-top: 3rem;
  }
}